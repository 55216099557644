<template>
  <div style="width: 100%; padding: 10px">
    <h1>{{ $options.name }}</h1>
    <EMasterDetailView
      container_name="drinks"
      header_attribute="name"
      description_attribute="type"
      image_attribute="picture_url"
      :detail_view_content="detail_view_content"
      :management_view_content="management_view_content"
    ></EMasterDetailView>
  </div>
</template>

<script>
export default {
  name: "Drinks",
  data() {
    return {
      // Define here the custom attributes and container
      detail_view_content: [
        {
          name: this.$t("drinks.base_alcohol"),
          value: "base_alcohol",
        },
        {
          name: this.$t("drinks.type"),
          value: "type",
        },
        {
          name: this.$t("drinks.ingredients"),
          value: "ingredients",
          formatter: this.formatIngredient,
        },
        {
          name: this.$t("drinks.rating"),
          value: "rating",
          formatter: this.formatRating,
        }
      ],

      management_view_content: [
        {
          name: "name",
          label: "Name",
          type: "text",
          required: true
        },
        {
          name: "picture_url",
          label: this.$t("elements.image"),
          type: "file"
        },
        {
          name: "type",
          label: this.$t("drinks.type"),
          type: "select",
          options: [
            "Longdrink",
            "Cocktail"
          ]
        },
        {
          name: "base_alcohol",
          label: this.$t("drinks.base_alcohol"),
          type: "select",
          options: [
            "Gin",
            "Whisky",
            "Vodka",
            "Rum",
            "Tequila",
            "Champangner",
            "Weisswein",
            "Rotwein",
            "Prosecco",
            "Likör"
          ]
        },
        {
          name: "ingredients",
          label: this.$t("drinks.ingredients"),
          type: "textarea"
        },
        {
          name: "rating",
          label: this.$t("drinks.rating"),
          type: "rating"
        }
      ]
    };
  },
  methods: {
    formatIngredient(ingredients) {
      var ingredientsArray = ingredients.split(",");
      return ingredientsArray.join("<br>");
    },
    formatRating(rating) {
      var ratingString = "";
      rating = parseInt(rating);
      for (var i = 0; i < rating; i++) {
        ratingString += '<i class="fas fa-star"></i>';
      }
      for (var ir = rating; ir < 5; ir++) {
        ratingString += '<i class="far fa-star"></i>';
      }
      return ratingString;
    }
  },
};
</script>

<style>
.detail_view {
  margin: 10px;
}
</style>